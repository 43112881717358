
import { UserOutlined } from "@ant-design/icons-vue";
import { defineComponent, PropType } from "vue";
import { Doctor } from "../../types";

export const DoctorComponent = defineComponent({
  components: {
    UserOutlined,
  },
  props: {
    data: {
      type: Object as PropType<Doctor>,
      required: true,
    },
  },
});
export default DoctorComponent;
